<template>
  <div id="MACAuth">
    <h1 class="my-4" v-if="entry || bcds == null">{{ $t('system.macauth') }}</h1>
    <b-alert show variant="info">
      <i18n path="views.macauth.mpsk_notice.text"
            tag="p" for="views.macauth.mpsk_notice">
        <template #request_link>
          <a target="_blank" href="https://www.scc.kit.edu/dienste/internet-of-things.php">{{
              $t('views.macauth.mpsk_notice.request_text')
            }}</a>
        </template>
      </i18n>
    </b-alert>
    <b-checkbox v-if="entry" :value="true" :unchecked-value="false" v-model="only_own" switch>
      {{ $t('views.macauth.show_only_own_bcds') }}
    </b-checkbox>
    <BCDList :bcds="bcds" :bcd2subnets="bcd2subnets" :bcd2vlans="bcd2vlans" :ous="ous" :bcd2ous="bcd2ous"
             :default_sort_by="'name'" :bcd2vnis="bcd2vnis" :show_usage="false" :filter_callback="search_box_filter_callback"
    ></BCDList>
    <APIObjectDBEditor object_fq_name="macauth.client" :object_function="db_editor_object_function"
              modal_id="dbeditor_macauth" :old_data="db_editor_old_data" :presets="db_editor_presets"
              :non_optionals_order="['bcd_name', 'mac_addr', 'description', 'do_refresh_wpa_key']"
              :object_title="db_editor_object_title"
    ></APIObjectDBEditor>
  </div>
</template>

<script>
import MACAuth from '@/api-services/macauth.service'
import ApiUtil from '@/util/apiutil'
import RangeService from '@/api-services/dns_bcd.service'
import BCDList from '@/components/BCDList.vue'
import APIObjectDBEditor from '@/components/db-editor/APIObjectDBEditor.vue'

export default {
  name: 'MacAuthOverview',
  components: { APIObjectDBEditor, BCDList },
  data() {
    return {
      qr_item: null,
      only_own: true,
      db_editor_object_function: 'create',
      db_editor_old_data: {},
      db_editor_presets: {},
      db_editor_object_title: {},
      bcds: null,
      clients_by_bcd: null,
      filter_text: '',
      entry: true,
      waiting: false,

      bcd2vlans: null,
      bcd2subnets: null,
      ous: null,
      bcd2ous: null,
      bcd2vnis: null
    }
  },
  created() {
    this.fetchData()
  },
  watch: {
    only_own: {
      immediate: true,
      async handler() {
        await this.fetchData()
      }
    },
    $route() {
      this.fetchData()
    }
  },
  methods: {
    search_box_filter_callback(filter_expr) {
      filter_expr = filter_expr.replace(/[^0-9a-fA-F]/g, '')
      if (filter_expr.match(/^[0-9a-fA-F]{12}$/)) {
        // looks like a mac address => open popup
        MACAuth.search_client(this.$store.state, filter_expr).then((response) => {
          if (response.data.client_list.length === 1) {
            this.editClient(response.data.client_list[0])
          }
        })
        return ''
      }
      return null
    },
    async fetchData() {
      const response = await RangeService.getOverview(this.$store.state)
      this.ous = response.data.unit_list
      this.bcds = response.data.bcd_list
      this.bcd2ous = response.data.bcd2ou_list
      this.bcd2subnets = ApiUtil.dict_of_lists_by_value_of_array(response.data.ip_subnet_list, 'bcd')
      this.bcd2vlans = ApiUtil.dict_of_lists_by_value_of_array(response.data.vlan_list, 'bcd')
      this.bcd2vnis = ApiUtil.dict_by_value_of_array(response.data.vxlan_list, 'bcd')
    },
    editClient(item) {
      this.db_editor_object_function = 'update'
      this.db_editor_old_data = item
      this.db_editor_presets = item
      this.db_editor_object_title = item.mac_addr + ' ' + this.$t('views.macauth.in_bcd') + ' ' + item.bcd_name
      this.$root.$emit('bv::show::modal', 'dbeditor_macauth')
    },
  }
}
</script>

<style>
</style>
