import TransactionService from './transaction.service'

export default {
  list (config, own) {
    const ta = [
      { name: 'nd.bcd.list', old: { is_own: own, sorting_params_list: ['name'] } }, // BCDs
      { name: 'macauth.client.list', inner_join_ref: { bcd_list: 'default' } } // Clients
    ]
    return TransactionService.execute(config, ta)
  },
  get (config, bcd) {
    const ta = [
      { name: 'nd.bcd.list', old: { name: bcd, sorting_params_list: ['name'] } }, // BCDs
      { name: 'macauth.client.list', inner_join_ref: { bcd_list: 'default' } } // Clients
    ]
    return TransactionService.execute(config, ta)
  },
  search_client (config, mac_addr) {
    const ta = [
      { name: 'macauth.client.list', old: { mac_addr: mac_addr } }
    ]
    return TransactionService.execute(config, ta)
  }
}
